<template>
<div>

    <div v-if="false" class="bg-default">
        <header class="header">
            <div class="header-left">
                <a href="#">
                    <img class="header-settings-icon" src="img/settings-icon.png" alt="Settings">
                </a>
            </div>
            <div class="header-center">
            </div>
            <div class="header-right">
                <a @click="exit()">
                    <img class="header-settings-icon" src="img/exit-icon.png" alt="Exit">
                </a>
            </div>
        </header>
        <main class="main">
            <div class="container">
                <div class="card card-centerred">
                    <div class="card-icon card-icon-circle mb-32">
                        <img src="img/fail-icon.png" alt="Fail icon">
                    </div>
                    <div class="card-info mb-16">
                        A tőzsdei kereskedéshez
                    </div>
                    <div class="card-title mb-16">
                        Nem valutára,<br>
                        hanem devizára
                    </div>
                    <div class="card-info mb-48">
                        van szükséged
                    </div>
                    <div class="card-info">
                        Segítségként devizában átváltottunk neked XX.XXX tallért YY.YYY dollárra.
                    </div>
                </div>
            </div>
        </main>
        <footer class="footer">
            <div class="container">
                <div class="footer-button-only">
                    <a href="#" class="button button-blue">Tőzsdézzünk!</a>
                </div>
            </div>
        </footer>
    </div>


    <div v-if="true" class="bg-default">
        <header class="header">
            <div class="header-left">
                <a href="#">
                    <img class="header-settings-icon" src="img/settings-icon.png" alt="Settings">
                </a>
            </div>
            <div class="header-center">
            </div>
            <div class="header-right">
                <a @click="exit()">
                    <img class="header-settings-icon" src="img/exit-icon.png" alt="Exit">
                </a>
            </div>
        </header>
        <main class="main">
            <div class="container">
                <div class="card card-centerred">
                    <div class="card-icon card-icon-circle mb-48">
                        <img src="img/success-icon.png" alt="Success icon">
                    </div>
                    <div class="card-title mb-24">
                        Jó döntés!
                    </div>
                    <div class="card-info mb-86">
                        A tőzsdézéshez valóban devizára van szükséged.
                    </div>
                    <div class="card-info">
                        Devizában átváltottunk neked XX.XXX tallért YY.YYY dollárra.<br>
                        Mivel jól döntöttél, a játék végén kapsz 5.000 tallér bónuszt.
                    </div>
                </div>
            </div>
        </main>
        <footer class="footer">
            <div class="container">
                <div class="footer-button-only">
                    <a href="#" class="button button-blue">Tőzsdézzünk!</a>
                </div>
            </div>
        </footer>
    </div>
</div>
</template>

<script>
export default {}
</script>

<style>

</style>
